import React from "react";
// import {Row, Col} from "react-materialize"
import { Link } from "gatsby"
// import Logo from './logoWhite'
// import { FaLinkedinIn, FaFacebookF, FaTwitter, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa/';
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { FaPhone } from "@react-icons/all-files/fa/FaPhone"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import {StaticImage} from 'gatsby-plugin-image'
import  Row from "react-materialize/lib/Row";
import  Col from "react-materialize/lib/Col";




const Footer = () => (

  <footer >
    <div className="page-footer color-0-bg">
    <div className="container1">
      <Row>
        <Col l={4} m={12} s={12} className="hide-on-med-and-down">
        <Link to="/" >
          <h3 className="title color-2">BizHighTech</h3>
          <StaticImage src="../images/logowhite.svg" height="80" layout="fixed" className="svglogo" placeholder="none" alt="Bizhightech logo footer" />
          {/* <Logo myClass="footerimage" /> */}
        </Link>
        </Col>
        <Col l={4} m={12} s={12} className="center hide-on-large-only">
        {/* <Link to="/" ><Image className="footer-logo" /></Link> */}
        <Link to="/" >
          <h3 className="title color-2">BizHighTech</h3>
          <StaticImage src="../images/logowhite.svg" height="80" layout="fixed" className="svglogo" placeholder="none" alt="Bizhightech logo footer hidden" />
          {/* <Logo myClass="footerimage" /> */}
        </Link>
        </Col>
        <Col l={3} m={4} s={4}>
          <h3 className="footer-list-head">Pages</h3>
          <ul className="footer-list">
            <li><Link className="color-2" to="/services">Services</Link></li>
            <li><Link className="color-2" to="/tech">Technologies</Link></li>
            <li><Link className="color-2" to="/about">About</Link></li>
          </ul>
        </Col>
        <Col l={5} m={8} s={8}>
          <h3 className="footer-list-head">Contact</h3>
            <ul className="socials footer-list">
              <li><a href="https://web.facebook.com/bizhightech/" aria-label="social link facebook"><FaFacebookF className="color-3" /></a></li>
              <li><a href="https://twitter.com/bizhightech" aria-label="social link twitter"><FaTwitter className="color-3" /></a></li>
              <li><a href="https://www.linkedin.com/company/bizhightech" aria-label="social link linkedin"><FaLinkedinIn className="color-3" /></a></li>
              {/* <li><Link className="grey-text text-lighten-3" to="/contact">Contact</Link></li> */}
            </ul>
            <ul className="footer-list contact">
              <li><FaEnvelope className="color-3" /> : info@bizhightech.com</li>
              <li><FaPhone className="color-3 reverse" /> : +50947007622, +50949007632</li>
              <li><FaMapMarkerAlt className="color-3" /> : 11b, Puits Blain 4, Routes de Freres, Petion-Ville, Haiti</li>
            </ul>
        </Col>
      </Row>
    </div>
    <div className="footer-copyright">
      <div className="container1">
      © 2019, Designed by
      <a className="grey-text text-lighten-4 right" href="https://bizhightech.com/">BizHighTech</a>
      </div>
    </div>
    </div>
  </footer>

)

export default Footer