import React, {useRef, useEffect} from "react";
// import { Link } from "gatsby"
// import Logo from './logo'
import { gsap } from 'gsap'
import { SlowMo } from 'gsap/EasePack'
import Language from './language'
import { useIntl, Link } from "gatsby-plugin-intl"
import Sidenav from "../ui/sidenav";
import {StaticImage} from 'gatsby-plugin-image'
// import MobNav from './mobilenav'
gsap.registerPlugin(SlowMo)


const Nav = (props) => {

  let navEl = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  // let titleEl = useRef(null)
  // let bgEl = useRef(null)
  const intl = useIntl()
  useEffect(() => {
    // gsap.to(bgEl, {duration: 1.2, y: 20, ease: "slow(0.1, 0.4, false)"})
    // gsap.to(titleEl, {opacity: 1, duration: 1.2, x: 20, ease: "slow(0.7, 0.4, false)", delay: 1})
    gsap.to(navEl, {opacity: 1, duration: .4, y: 20, ease: "back", delay: .4, stagger: 0.1})
  }, [])

  // gsap.to(navEl, {opacity: 1, duration: .8, y: 20, ease: "back", delay: 1.2, stagger: 0.1})

  return(
        
    <nav className="transparent"> 
      <div className="nav-wrapper">
        <div className="container1">
          <Link to="/" className="brand-logo navbar-brand">
            <StaticImage src="../images/logo.svg" height="50" layout="fixed" className="svglogo" placeholder="none" alt="Bizhightech logo" />
            {/* <Logo myClass="navimage" /> */}
            {/* <h3 className="brand-name" ref={el => titleEl = el}>Villa Sant&eacute;</h3> */}
          </Link>
          <ul  className="right hide-on-med-and-down color-1-text">
            <li ref={el => navEl[0] = el}><Link className="center-align" to="/services">{`${intl.formatMessage({ id: "services" })}`}</Link></li>
            <li ref={el => navEl[1] = el}>
              <Link className="center-align" to="/tech">{intl.formatMessage({ id: "tech" })}</Link>
            </li>
            {/* <li ref={el => navEl[2] = el}>
              <Link className="center-align" to="/works">{intl.formatMessage({ id: "works" })}</Link>
            </li> */}
            <li ref={el => navEl[2] = el}>
              <Link className="center-align" to="/about">{intl.formatMessage({ id: "about" })}</Link>
            </li>
            <li ref={el => navEl[3] = el}>
              <Link className="center-align" to="/blog">Blog</Link>
            </li>
            <li ref={el => navEl[4] = el}>
              <Link className="center-align contact-btn" to="/contact">{intl.formatMessage({ id: "contact" })}</Link>
            </li>          
          </ul>
          <Language ref={el => (navEl[5] = el)} />
          <Sidenav />
        </div>
      </div>
    </nav>
  // </div>
)};
export default Nav;