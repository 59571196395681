import React, { useEffect, useRef, useState} from 'react'
import '../styles/sidenav.css'
import { gsap } from 'gsap'
import {FaBars, FaTimes} from 'react-icons/fa'
import { isMobile } from 'react-device-detect'
import {Link} from "gatsby";
import {StaticImage} from 'gatsby-plugin-image'

const Sidenav = () => {

  const [active, setActive] = useState(false)

  let headText = useRef(null)
  let items = useRef(null)

  useEffect(() => {
    gsap.from(headText, {opacity: 0, duration: 1.8, y: -20, ease: "back", delay: .6})
    gsap.from(items.children, {opacity: 0, duration: .8, x: -20, ease: "back", delay: .5, stagger: .1})
    // gsap.from(".mysidenav", {opacity: 0, duration: .8, x: -20, ease: "power.Out", delay: .2})

    isMobile && active && gsap.to(".navcontrol", {x:220, duration: 0.5})
    isMobile && !active && gsap.to(".navcontrol", {x:0, duration: 0.5, delay:.2})
    active && gsap.to(".mysidenav", {x:220, duration: 0.5, delay:.2})
    !active && gsap.to(".mysidenav", {x:0, duration: 0.5})

  }, [headText, items, active])


  function changeNav(){
    isMobile && setActive(!active)
  }

  return(
    <div>
      {isMobile && <button 
        className= "circle white navcontrol"
        onClick={changeNav}
        aria-label="navigation control"
      >
        <FaBars className="navopen color-1-text" />
      </button>}
      <div>
        <div className="mysidenav color-6">
            <div className="sidenav-head center">
          <Link to="/">
              {/* <Logo mystyle="sidenav-head-logo" /> */}
              <StaticImage src="../images/logowhite.svg" height="50" layout="fixed" className="svglogoside" placeholder="none" alt="Bizhightech logo sidenav" />
              <h3 ref={el => headText = el} className="sidenav-head-logo-text color-0-text">BizHighTech</h3>                   
          </Link>
            </div>
          <ul ref={ el => items = el} className="sidenav-item-list">
            <Link to="/services" className="sidenav-item color-2">Services</Link>
            <Link to="/tech" className="sidenav-item color-2">Technologies</Link>
            <Link to="/about" className="sidenav-item color-2">About</Link>
            <Link to="/blog" className="sidenav-item color-2">Blog</Link>
            <Link to="/contact" className="sidenav-item color-2">Contact</Link>
          </ul>
        </div>
      </div>
    </div>
  )
  
}

export default Sidenav
