import React, {forwardRef} from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"


const languageName = {
  en: "EN",
  fr: "FR",
  ht: "HT",
  es: "ES"
}

const Language = forwardRef((props, ref) => {
  return (
    <div className="lang">
      {/* <a>English</a>  */}
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          <div className="dropdown1" ref={ref}>
            <div className="dropbtn">
              {languageName[currentLocale]} 
            </div>
            <div className="dropdown-content1">
              {languages.map(language => (

                <div
                  role="button" 
                  tabIndex={0}
                  key={language}
                  onClick={() => changeLocale(language)}
                  onKeyDown={() => changeLocale(language)}
                  style={{
                    backgroundColor: currentLocale === language ? `#faf61d` : `#be0aac`,
                    color: currentLocale === language ? `#5a4fec` : `white`,
                    cursor: `pointer`,
                  }}
                  className="center"
                >
                  {languageName[language]}
                </div>

                ) 
              

              )}
            </div>
          </div>
        }
      </IntlContextConsumer>
    </div>
  )
})

export default Language